<template>
  <div class="mt--8 pb-5 container" v-if="rule != null">
    <div class="card bg-secondary border-0">
      <b-row class="rule-edit">
        <b-col lg="10" class="mx-auto">
          <b-row class="border-bottom sticky-top bg-white page-header">
            <div class="d-flex align-items-center">
              <b-link @click="$router.go(-1)">
                <font-awesome-icon icon="arrow-left-long" />
              </b-link>
              <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">Back</h5>
              <Spinner v-if="isLoading"></Spinner>
              <b-button @click.prevent="saveSettings" :disabled="isLoading">Update</b-button>
            </div>
          </b-row>
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  id="input-group-2"
                  label="Details"
                  label-for="details"
                >
                  <b-form-textarea
                    id="details"
                    v-model="rule.details"
                    placeholder="Enter details"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Status" label-for="status" class="mb-3">
                  <b-form-select
                    id="status"
                    v-model="rule.status"
                    required
                    :options="options"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group
                  id="input-group-4"
                  label="Destination Prefix"
                  label-for="destinationPrefix"
                >
                  <b-form-input
                    id="destinationPrefix"
                    name="destinationPrefix"
                    v-model="rule.destinationPrefix"
                    type="text"
                    placeholder="e.g: '100.10.0.0/16'"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group
                  id="input-group-4"
                  label="Rate Limit"
                  label-for="rate_limit"
                >
                  <b-form-input
                    id="rate_limit"
                    name="rate_limit"
                    v-model="rule.rate_limit"
                    type="text"
                    placeholder="Enter rate limit"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="input-group-7"
                  label="Source Port"
                  label-for="sourcePort"
                >
                  <b-form-input
                    id="sourcePort"
                    name="sourcePort"
                    v-model="rule.sourcePort"
                    type="number"
                    placeholder="Enter sourcePort"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group
                  id="input-group-5"
                  label="Source Prefix"
                  label-for="sourcePrefix"
                >
                  <b-form-input
                    id="sourcePrefix"
                    name="sourcePrefix"
                    v-model="rule.sourcePrefix"
                    type="text"
                    placeholder="e.g: '100.10.0.0/16'"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group
                  id="input-group-6"
                  label="Ip Protocol"
                  label-for="ipProtocol"
                >
                  <b-form-select
                    id="ipProtocol"
                    v-model="rule.ipProtocol"
                    :options="ipProtocolOptions"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col lg="3" v-if="rule.ipProtocol == 'Unknown'">
                <b-form-group
                  id="input-group-10"
                  label="Specific Protocols(optional)"
                  label-for="extraProtocols"
                >
                  <b-form-input
                    id="extraProtocols"
                    name="extraProtocols"
                    v-model="extraProtocols"
                    :disabled="rule.ipProtocol !== 'Unknown' "
                    type="number"
                    min="1"
                    placeholder="e.g 9"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="input-group-10"
                  label="ICMP Code"
                  label-for="ICMPCode"
                >
                  <b-form-input
                    id="ICMPCode"
                    name="ICMPCode"
                    v-model="rule.ICMPCode"
                    type="number"
                    placeholder="Enter ICMPCode"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="input-group-12"
                  label="Packet Length"
                  label-for="packetLength"
                >
                  <b-form-input
                    id="packetLength"
                    name="packetLength"
                    v-model="rule.packetLength"
                    type="number"
                    placeholder="Enter Packet Length"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group
                  id="input-group-8"
                  label="Destination Port"
                  label-for="destinationPort"
                >
                  <b-form-input
                    id="destinationPort"
                    name="destinationPort"
                    v-model="rule.destinationPort"
                    type="number"
                    placeholder="Enter destinationPort"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group
                  id="input-group-9"
                  label="ICMP Type"
                  label-for="ICMPType"
                >
                  <b-form-input
                    id="ICMPType"
                    name="ICMPType"
                    v-model="rule.ICMPType"
                    type="text"
                    placeholder="e.g: '1'"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group
                  id="input-group-11"
                  label="TCP Flags"
                  label-for="TCPflags"
                >
                  <b-form-input
                    id="TCPflags"
                    name="TCPFlags"
                    v-model="rule.TCPflags"
                    type="text"
                    placeholder="e.g: FS, SA, R"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group id="input-group-13" label="DSCP" label-for="DSCP">
                  <b-form-input
                    id="DSCP"
                    name="DSCP"
                    v-model="rule.DSCP"
                    type="text"
                    placeholder="e.g: 10"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="input-group-14"
                  label="FR Encoding"
                  label-for="FR Encoding"
                >
                  <b-form-input
                    id="FREncoding"
                    name="FREncoding"
                    v-model="rule.FREncoding"
                    type="text"
                    placeholder="Enter FREncoding"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import FlowSpecService from "../../services/flow-spec.service";
import { notify } from "../../helpers/index";
import "vue3-toastify/dist/index.css";
import Spinner from "../../components/spinner/spinner.vue";
export default {
  name: "FlowSpecEdit",
  components: {
    Spinner,
  },
  data: function () {
    return {
      rule: null,
      isLoading: false,
      details: "",
      status: "active",
      destinationPrefix: "",
      sourcePrefix: "",
      ipProtocol: "",
      sourcePort: null,
      destinationPort: null,
      ICMPType: "",
      ICMPCode: 0,
      TCPflags: "",
      packetLength: 0,
      DSCP: "",
      FREncoding: "",
      errors: {},
      extraProtocols: "",
      options: [
        {
          value: "active",
          text: "Active",
        },
        {
          value: "inactive",
          text: "Inactive",
        },
      ],
      ipProtocolOptions: [
        {
          value: "TCP",
          text: "TCP",
        },
        {
          value: "UDP",
          text: "UDP",
        },
        {
          value: "Unknown",
          text: "Any",
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.getRuleById();
  },
  methods: {
    async getRuleById() {
      this.isLoading = true;
      const response = await FlowSpecService.getFlowSpecRuleById(
        "flowspec/" + this.$route.params.id
      );
      if (response?.status == 200) {
        this.rule = response.data?.rule;
        console.log(response.data)
        if (Number(this.rule.ipProtocol) == this.rule.ipProtocol ){
          this.extraProtocols = this.rule.ipProtocol;
          this.rule.ipProtocol = 'Unknown';
        } 
      }
      this.isLoading = false;
    },
    async saveSettings() {
      this.errors = {};
      try{
        this.isLoading = true;
        this.rule.ipProtocol = this.rule.ipProtocol !== 'UDP' && this.rule.ipProtocol !== 'TCP' && this.extraProtocols ? this.extraProtocols?.toString() : this.rule.ipProtocol;
        this.rule.sourcePort = this.rule.sourcePort ? this.rule.sourcePort * 1 : null;
        this.rule.destinationPort = this.rule.destinationPort ? this.rule.destinationPort * 1 : null;
        const response = await FlowSpecService.updateFlowSpecRules(
          "flowspec/update/" + this.$route.params.id,
          this.rule
        );
        if (response.status == 200) {
          this.rule = response.data.rule;
          notify("Manual Rule updated successfully");
          this.isLoading = false;
          this.$router.push({ name: "rule" });
        }
        else {
          notify("Error updating Manual Rule");
          this.isLoading = false;
        }
      }
      catch {
        notify("Error updating Manual Rule");
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.bg-secondary {
  background-color: #f7fafc !important;
}
</style>
